import { QueryableStorage } from '@weavix/sync';
import { observable } from 'mobx';
import { getAccountStorage } from './get-account-storage';
import { createContext, model, Model, modelAction, customRef, Ref } from './mobx-weavix';
import { pick } from 'lodash';

type UpdatableItem<T> = {
    id: string;
    update?: (value: Partial<T>) => any;
};

// cache disabled due to liveness issues - calls to this.map are missing data
const CACHE_ENABLED = true;

/**
    Generic wrapper to create a store for sync'ing items across accounts
    Auto populates accountId = partition value
    Creates context/ref to be used to get values
    Store is loaded lazily for ease of startup (mobx items get created ~100-1000 per second depending on device)
*/
export function createStore<T extends UpdatableItem<T>, S extends { id: string } = T>(name: string, Create?: new (item: Partial<T>, unobservable?: boolean) => T, storage?: () => QueryableStorage<S>) {
    const cacheObjects = new Map<string, T>();
    const create = (value: T, cacheId?: string) => {
        if (cacheId && cacheObjects.has(cacheId)) return cacheObjects.get(cacheId);

        const obj = Create ? new Create(value, !!cacheId) : value as any as T;
        if (cacheId) cacheObjects.set(cacheId, obj);
        return obj;
    };

    @model(`SyncStore/${name}`)
    class Store extends Model({
    }) {
        private map = observable.map<string, T>({}, { deep: false });
        private cache = new Map<string, any>();
        loaded: Promise<any>;

        has(id: string) {
            return this.cache.has(id) || this.map.has(id);
        }

        get(id: string, value?: Partial<T>) {
            this.retrieveItemFromCache(id, value);
            return this.map.get(id);
        }

        getStatic(id: string) {
            if (this.cache.has(id)) return create(this.cache.get(id), id);
            return this.map.get(id);
        }

        @modelAction
        private retrieveItemFromCache(id: string, value?: Partial<T>) {
            if (CACHE_ENABLED && this.cache.has(id)) {
                this.map.set(id, create(this.cache.get(id)));
                this.cache.delete(id);
                cacheObjects.delete(id);
            } else if (!this.map.has(id) && value) {
                this.map.set(id, create(value as T));
            }
        }

        /**
         * Returns matching items without removing them from the cache and creating an observable mobx object.
         */
        getAllStatic(query: (Partial<T> | ((val: T) => any)) = {}) {
            const test = typeof query === 'function' ? query : val => Object.keys(query).every(key => query[key] === val[key]);
            const result = [];
            for (const value of this.map.values()) {
                if (test(value)) result.push(create(value, value.id));
            }
            if (CACHE_ENABLED) {
                for (const value of this.cache.values()) {
                    if (test(value)) result.push(create(value, value.id));
                }
            }
            return result;
        }

        getAll(query: (Partial<T> | ((val: T) => any)) = {}) {
            const test = typeof query === 'function' ? query : val => Object.keys(query).every(key => query[key] === val[key]);
            this.retrieveMatchingItemsFromCache(test);
            const result = [];
            for (const value of this.map.values()) {
                if (test(value)) result.push(value);
            }
            return result;
        }

        @modelAction
        private retrieveMatchingItemsFromCache(filter: (cacheItem: any) => boolean) {
            if (CACHE_ENABLED) {
                for (const value of this.cache.values()) {
                    if (filter(value)) {
                        // Ensure any results from cache are put in store
                        this.map.set(value.id, create(value));
                        this.cache.delete(value.id);
                        cacheObjects.delete(value.id);
                    }
                }
            }
        }

        constructor() {
            super();
            context.setDefault(this);

            const emitter = (storage ? storage() : getAccountStorage<S>(name).Instance()).query();
            emitter.on('load', values => this.loadValues(values));
            emitter.on('update', ({ value, keys }) => this.updateValue(value, keys));
            emitter.on('delete', value => this.deleteValue(value));
            Object.defineProperty(this, 'loaded', { get: () => emitter.wait() });
        }

        @modelAction
        loadValues(values: any[]) {
            const start = new Date().getTime();
            this.cache.clear();
            cacheObjects.clear();
            const keys = new Set(this.map.keys());
            this.map.clear();
            values.forEach(x => {
                if (!x.accountId) x.accountId = x.partition;
                if (!CACHE_ENABLED || keys.has(x.id)) {
                    this.map.set(x.id, create(x));
                } else {
                    this.cache.set(x.id, x);
                }
            });

            console.log(`Loaded mobx ${this.cache.size || this.map.size} ${name} in ${new Date().getTime() - start} ms`);
        }

        @modelAction
        updateValue(value: any, keys?: any) {
            if (!value.accountId) value.accountId = value.partition;
            if (CACHE_ENABLED && this.cache.has(value.id)) {
                this.cache.set(value.id, value);
                cacheObjects.delete(value.id);
            } else {
                const existing = this.map.get(value.id);
                if (existing?.update) existing.update(keys ? pick(value, keys) : value);
                else this.map.set(value.id, create(value));
            }
        }

        @modelAction
        deleteValue(value: any) {
            this.cache.delete(value.id);
            cacheObjects.delete(value.id);
            this.map.delete(value.id);
        }
    }

    const context = createContext<Store>();
    const ref: (id: string) => Ref<T> = customRef<T>(id => context.get(ref)?.get(id));

    return {
        context,
        ref,
        Store,
    };
}
