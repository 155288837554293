import { Pipe, PipeTransform } from '@angular/core';
import TimeAgo from 'javascript-time-ago';
import { TranslationService } from '../services/translation.service';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common';

import de from 'javascript-time-ago/locale/de';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es-MX';
import fr from 'javascript-time-ago/locale/fr';
import it from 'javascript-time-ago/locale/it';
import pt from 'javascript-time-ago/locale/pt';
import my from 'javascript-time-ago/locale/my';
import sw from 'javascript-time-ago/locale/sw';
import sq from 'javascript-time-ago/locale/sq';
import ko from 'javascript-time-ago/locale/ko';
import th from 'javascript-time-ago/locale/th';
import fil from 'javascript-time-ago/locale/fil';
import ps from 'javascript-time-ago/locale/ps';
import ja from 'javascript-time-ago/locale/ja';
import vi from 'javascript-time-ago/locale/vi';
import zh from 'javascript-time-ago/locale/zh';
import uk from 'javascript-time-ago/locale/uk';
import lo from 'javascript-time-ago/locale/lo';
import ms from 'javascript-time-ago/locale/ms';
import fa from 'javascript-time-ago/locale/fa';
import ne from 'javascript-time-ago/locale/ne';

import { HttpService } from '../services/http.service';

TimeAgo.addLocale(en);
TimeAgo.addLocale(es);
TimeAgo.addLocale(pt);
TimeAgo.addLocale(fr);
TimeAgo.addLocale(de);
TimeAgo.addLocale(it);
TimeAgo.addLocale(my);
TimeAgo.addLocale(sw);
TimeAgo.addLocale(sq);
TimeAgo.addLocale(ko);
TimeAgo.addLocale(th);
TimeAgo.addLocale(fil);
TimeAgo.addLocale(ps);
TimeAgo.addLocale(ja);
TimeAgo.addLocale(vi);
TimeAgo.addLocale(zh);
TimeAgo.addLocale(uk);
TimeAgo.addLocale(lo);
TimeAgo.addLocale(ms);
TimeAgo.addLocale(fa);
TimeAgo.addLocale(ne);

export function fromNow(date: string | number | Date, now?: string | number | Date): number {
    return now != null
        ? new Date(now).getTime() - new Date(date).getTime()
        : new Date().getTime() - new Date(date).getTime() - HttpService.serverTimeDifference;
}

export function timeAgo(date: string | Date, language?: string) {
    return new TimeAgo(language).format(new Date(new Date(date).getTime() + HttpService.serverTimeDifference - 1000));
}

@Pipe({
    name: 'timeAgo',
    pure: false,
    standalone: true,
})
export class TimeAgoPipe implements PipeTransform {

    private timeAgo: TimeAgo;
    private cache;
    private cacheTime;
    private currentValue: Date | string;

    constructor(private translationService: TranslationService) {
        this.timeAgo = new TimeAgo(this.translationService.getLanguage());
    }

    transform(inputValue: Date | string, type: 'normal' | 'message' = 'normal', relativeToTimestamp?: Date | string | number) {
        const now = Date.now();
        const current = new Date();
        if (!this.cache || now > this.cacheTime || inputValue !== this.currentValue) {
            this.currentValue = inputValue;
            const value = new Date(inputValue); // Don't mutate input

            let offset = HttpService.serverTimeDifference;
            if (relativeToTimestamp) {
                offset = now - (new Date(relativeToTimestamp).getTime() || now);
                if (offset < 0) console.warn('timeago offset less than 0', offset);
            }

            // if relative time is passed in, we add difference between now and the relative time to the value
            // and pass that into TimeAgo, so TimeAgo reads as if it is respecting the relative time
            const valueWithOffset = new Date(value.getTime() + offset);
            const formatForTeamsApp = () => {
                const sameDay = current.getDate() === valueWithOffset.getDate() &&
                    current.getMonth() === valueWithOffset.getMonth() &&
                    current.getFullYear() === current.getFullYear();
                const dp = new DatePipe(this.translationService.getLocale());
                return dp.transform(valueWithOffset, !sameDay ? 'M/d' : 'h:mm aa');
            };
            const formatForMessage = () => {
                const dp = new DatePipe(this.translationService.getLocale());
                const now = new Date();
                const sameMinute = valueWithOffset.getTime() > now.setMinutes(now.getMinutes() - 1);
                if (sameMinute) return this.translationService.getImmediate('crews.channels.just-now');
                const sameHour = valueWithOffset.getTime() > now.setMinutes(now.getMinutes() - 59);
                if (sameHour) {
                    const minutesAgo = 60 + Math.round((((now.getTime() - valueWithOffset.getTime()) % 86400000) % 3600000) / 60000);
                    return `${minutesAgo} ${this.translationService.getImmediate('crews.channels.minutes-ago')}`;
                }
                const sameDay = valueWithOffset.getTime() > now.setMinutes(now.getMinutes() - 1380);
                const sameWeek = valueWithOffset.getTime() > now.setMinutes(now.getMinutes() - 8640);
                return dp.transform(valueWithOffset, sameDay ? 'h:mma' : sameWeek ? 'E h:mma' : 'MM/dd/yyyy h:mma');
            };

            this.cache = value.getTime()
                ? environment.teamsApp ? formatForTeamsApp()
                    : type === 'message' ? formatForMessage() : this.timeAgo.format(valueWithOffset)
                : this.translationService.getImmediate('generics.never');
            if (value.getTime()) this.cacheTime = now + Math.abs(now - value.getTime()) / 60;
        }
        return this.cache;
    }
}
