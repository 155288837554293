import { PermissionAction } from '../permissions/permissions.model';

export enum AppRoute {
    Activate = 'activate',
    Add = 'add',
    Audio = 'audio',
    Alerts = 'alerts',
    AlertTypes = 'alert-types',
    ApiKeys = 'api-keys',
    Channels = 'channels',
    Chat = 'chat',
    Companies = 'companies',
    Compliance = 'compliance',
    Configuration = 'configuration',
    Crafts = 'crafts',
    Dashboard = 'dashboard',
    Dashboards = 'dashboards',
    DeviceManagement = 'device-management',
    DeviceNetworkStatus = 'device-network-status',
    Equipment = 'equipment',
    /** @deprecated use AppRoute.Sites instead. */
    Facilities = 'facilities',
    Forms = 'forms',
    GeofenceTypes = 'geofence-types',
    General = 'general',
    HoleWatch = 'hole-watch',
    Import = 'import',
    Items = 'items',
    ItemTypes = 'item-types',
    LiveLook = 'live-look',
    LocationDiagnostics = 'location-diagnostics',
    Map = 'map',
    NoAccess = 'no-access',
    WorkerEfficiency = 'worker-efficiency',
    PowerBiReport = 'report',
    PowerBiConfig = 'report-config',
    People = 'people',
    Profile = 'profile',
    Radio = 'radio',
    Safety = 'safety',
    SafetyObservations = 'safety-observations',
    SafetyObservationDetail = 'safety-observations/:id',
    SelectUsers = 'select-users',
    SelfRegistration = 'self-register',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    Sites = 'facilities',
    SmartRadio = 'smart-radio',
    Video = 'video',
    Users = 'users',
    UsersPending = 'pending',
    UsersRequested = 'requested',
    UsersBlocked = 'blocked',
    UsersImported = 'imported',
    WheresWalt = 'wheres-walt',
    Workflows = 'workflows',
}

// site specific permissions do not work for these
export const ACCOUNT_ONLY_PERMISSIONS = [
    PermissionAction.AdministerAccount,
];

// these items are associated with potentially multiple sites which can give permission to items individually
export const MULTI_SITE_PERMISSIONS = [
    PermissionAction.ViewPeople,
    PermissionAction.EditPeople,
    PermissionAction.ViewAdministratorDefinedChannels,
    PermissionAction.EditAdministratorDefinedChannels,
    PermissionAction.ViewCrafts,
    PermissionAction.EditCrafts,
    PermissionAction.ViewDeviceManagement,
    PermissionAction.EditDeviceManagement,
    PermissionAction.ViewItemTypes,
    PermissionAction.EditItemTypes,
    PermissionAction.ViewGeofenceTypes,
    PermissionAction.EditGeofenceTypes,
    PermissionAction.ViewSites,
    PermissionAction.EditSites,

    PermissionAction.ViewForms,
    PermissionAction.EditForms,
    PermissionAction.ViewAlertTypes,
    PermissionAction.ViewWorkflows,
    PermissionAction.EditAlertTypes,
    PermissionAction.EditWorkflows,
];

// someone must have at least one of the specified permissions in order to access the given route
export const ROUTE_PERMISSIONS: { [key in AppRoute]?: PermissionAction[] } = {
    [AppRoute.Alerts]: [PermissionAction.SendAlerts],
    [AppRoute.AlertTypes]: [PermissionAction.ViewAlertTypes],
    [AppRoute.Audio]: [],
    [AppRoute.Chat]: [],
    [AppRoute.Configuration]: [
        PermissionAction.ViewAdministratorDefinedChannels,
        PermissionAction.ViewAlertTypes,
        PermissionAction.ViewCrafts,
        PermissionAction.ViewForms,
        PermissionAction.ViewGeofenceTypes,
        PermissionAction.ViewItemTypes,
        PermissionAction.ViewSites,
        PermissionAction.ViewPeople,
        PermissionAction.ViewWorkflows,
    ],
    [AppRoute.Equipment]: [PermissionAction.ViewEquipmentLiveLook],
    [AppRoute.HoleWatch]: [PermissionAction.ViewConfinedSpaces],
    [AppRoute.LiveLook]: [PermissionAction.ViewWorkerLiveLook],
    [AppRoute.Safety]: [PermissionAction.SendAlerts, PermissionAction.ViewConfinedSpaces, PermissionAction.ViewSafetyObservation],
    [AppRoute.WorkerEfficiency]: [PermissionAction.ViewWorkerEfficiency],
    [AppRoute.People]: [PermissionAction.ViewWorkerLiveLook, PermissionAction.ViewWorkerEfficiency],
    [AppRoute.SmartRadio]: [PermissionAction.ViewDeviceManagement],
    [AppRoute.Sites]: [PermissionAction.ViewSites],
    [AppRoute.Users]: [PermissionAction.ViewPeople],
    [AppRoute.Video]: [],
    [AppRoute.Workflows]: [PermissionAction.ViewWorkflows],
    [AppRoute.Dashboards]: [],
    [AppRoute.Dashboard]: [],
    [AppRoute.PowerBiConfig]: [PermissionAction.AdministerAccount],
    [AppRoute.PowerBiReport]: [PermissionAction.AdministerAccount],
    [AppRoute.Forms]: [PermissionAction.ViewForms],
    [AppRoute.ApiKeys]: [PermissionAction.AdministerAccount],
    [AppRoute.General]: [PermissionAction.AdministerAccount],
    [AppRoute.Channels]: [PermissionAction.ViewAdministratorDefinedChannels],
    [AppRoute.Crafts]: [PermissionAction.ViewCrafts],
    [AppRoute.GeofenceTypes]: [PermissionAction.ViewGeofenceTypes],
    [AppRoute.ItemTypes]: [PermissionAction.ViewItemTypes],
    [AppRoute.SelfRegistration]: [PermissionAction.ViewRegistrationCodes],
    [AppRoute.DeviceManagement]: [PermissionAction.ViewDeviceManagement],
    [AppRoute.DeviceNetworkStatus]: [PermissionAction.EnableDeviceNetworkStatus/*, PermissionAction.ViewDeviceManagement*/],
    [AppRoute.Compliance]: [PermissionAction.AdministerAccount],
    [AppRoute.SafetyObservations]: [PermissionAction.ViewSafetyObservation],
    [AppRoute.LocationDiagnostics]: [PermissionAction.EnableLocationDiagnostics],
};
