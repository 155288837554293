import { UploadResponse } from '../upload/upload';
import { ChannelType } from './channel';
import { ChannelNotify } from './channel-notifications';


const ZIP_MIME_TYPES: string[] = [
    'application/zip',
    'application/x-zip-compressed',
    'multipart/x-zip',
    'application/x-gzip',
    'multipart/x-gzip',
    'application/x-compressed',
    'application/x-zip-compressed',
    'application/x-zip-compressed',
];

export const ALLOWED_FILE_MIME_TYPES: string[] = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/msword',
    'text/plain',
    'video/quicktime',
    'video/mp4',
    'video/mp2t',
    'video/mpeg',
    'audio/aac',
    'audio/mp4',
    'audio/ac3',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.ms-excel', // .xls
    'application/vnd.ms-powerpoint', // .pps
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // .ppsx
    'application/vnd.oasis.opendocument.text', // .odt
    'application/vnd.oasis.opendocument.spreadsheet', // .ods
    'application/vnd.oasis.opendocument.presentation', // .odp
].concat(ZIP_MIME_TYPES);

export enum MessageType {
    Audio = 'audio',
    Form = 'form',
    Page = 'page',
    Text = 'text',
    Video = 'video',
}

export enum FilterableContentType {
    Image = 'image',
    Video = 'video',
    File = 'file',
    Audio = 'audio',
    Link = 'link',
}

export enum ChannelSource {
    Mobile = 'mobile',
    Walt = 'walt',
    Web = 'web',
}

export enum SystemMessageType {
    AddedMember = 'added-member',
    AdminApi = 'admin-api',
    AnsweredCall = 'answered-call',
    AnsweredPage = 'answered-page',
    AutoReply = 'auto-reply',
    CalledChannel = 'called-channel',
    DeclinedCall = 'declined-call',
    DeclinedPage = 'declined-page',
    LeftChannel = 'left-channel',
    MissedCall = 'missed-call',
    MissedPage = 'missed-page',
    Paged = 'paged',
    RemovedMember = 'removed-member',
    RuleTriggered = 'rule-triggered',
    SupportTicketStatusChange = 'support-ticket-status-change',
}

// These types will be styled like a normal user message
export const USER_STYLED_SYSTEM_MESSAGE_TYPES: SystemMessageType[] = [
    SystemMessageType.AdminApi,
    SystemMessageType.RuleTriggered,
];

export enum MessageAttachmentType {
    Image = 'image',
    Video = 'video',
    File = 'file',
    Audio = 'audio',
    Link = 'link', // used in iOS share extension. but what is it?
}

export interface ChannelMessage {
    id?: {
        channelId?: string;
        date: Date;
        id?: string;
    };
    data?: ChannelMessageData;
    fromTeams?: boolean;
    location?: number[];
    message?: string;
    refId?: string;
    sender?: { // populated if not a system message
        id?: string;
    };
    sequence?: number;
    source?: ChannelSource;
    timedOut?: boolean;
    translations?: any;
    type?: MessageType;
    uploaded?: Date;
    edited?: Date;
    /** indicates an incoming PTT is being spoofed, which can only occur if the PTT is being sent from the Two Walt Demo */
    spoofed?: boolean;
}

export interface ChannelMessageData {
    attachments?: MessageAttachment[];
    buttons?: { acceptButtonText?: string, cancelButtonText?: string };
    duration?: number; // audio attachments only
    event?: any;
    file?: { id?: string; name?: string; };
    form?: { id?: string; version?: string; name?: string; title?: string; facilityId?: string; };
    linkPreviews?: LinkPreview[] | URLLinkPreview[];
    links?: string[];
    locale?: string;
    onBehalfOf?: { userId: string, fullName: string, locale?: string }; // for auto-replies, shows who the auto reply came from
    pager?: { id?: string; firstName?: string; lastName?: string; message?: string; };
    radio?: string; // "<serial> - <radio>"
    ruleId?: string;
    systemMessageType?: SystemMessageType; // if it is a system message
    systemMessageData?: SystemMessageData; // data related to system message information
    supportTicketAssignee?: string; // for support tickets, shows who the ticket is assigned to - likely needs to be consolidated into a onBehalfOf concept... someday.
}

export interface SystemMessageData {
    sender?: string; // String placed in the sender's name place on messages. Defaults to 'weavix integration' if not provided
    readAloud?: boolean; // Will force the system message to read aloud despite the user's settings
    accountId?: string; // The account id of the system message sender
}

export interface MessageAttachment {
    type: MessageAttachmentType;
    duration?: number;
    file: UploadResponse;
    thumbnail?: UploadResponse; // if type === image || type === video
    thumbnailUri?: string;
    trimEnd?: number;
}

export interface CarouselAttachment {
    id: string | undefined;
    name?: string;
    date: string | Date | undefined;
    sender?: { // populated if not a system message
        id?: string;
        firstName?: string;
        lastName?: string;
        avatarFile?: string;
    };
    messageId: string | undefined;
    attachment: MessageAttachment;
}

export function channelMessageToCarouselAttachment(message: ChannelMessageResponse): CarouselAttachment[] {
    return message.data?.attachments
        ? message.data.attachments
            .filter(x => ['image', 'video'].includes(x.type))
            .map(attachment => ({
                id: attachment.file?.id,
                name: attachment.file?.name,
                date: typeof message.date === 'string' ? message.date : message.date?.toISOString(),
                sender: message.sender,
                messageId: message.id,
                attachment,
            }))
        : [];
}

export interface LinkPreview {
    url: string;
    title?: string;
    siteName?: string;
    description?: string;
    imageUri?: string;
    images?: string[];
    videos?: string[];
    favicons?: string[];
}

export interface URLLinkPreview extends LinkPreview {
    isLoading?: boolean;
}

export interface Communication {
    id?: string;
    date: Date|string;
    facilityId: string;
    offsite: boolean;
    onsite?: boolean;
    personId?: string;
    type?: MessageType;
    location?: number[];
    source?: ChannelSource;
    duration?: number;
    count?: number;
}

export interface CreateMessageRequest {
    data?: ChannelMessageData;
    date?: Date;
    edited?: Date;
    fromTeams?: boolean;
    message?: string;
    recorded?: Date;
    refId?: string;
    sequence?: number;
    source?: ChannelSource;
    timedOut?: boolean;

    type?: MessageType;
    /** indicates an incoming PTT is being spoofed, which can only occur if the PTT is being sent from the Two Walt Demo */
    spoofed?: boolean;
}

export interface ChannelMessageResponse extends ChannelNotify {
    id?: string;
    channelId?: string;
    channelName?: string;
    channelType?: ChannelType;
    data?: ChannelMessageData;
    date?: Date | string; // it's a Date on the backend, but gets transmitted over the wire as a string
    fromTeams?: boolean;
    message?: string;
    refId?: string;
    sender?: { // populated if not a system message
        id?: string;
        firstName?: string;
        lastName?: string;
        avatarFile?: string;
    };
    type?: MessageType;
    edited?: Date;
}

export interface CallingSystemMessageRequest {
    type: SystemMessageType;
    /**
     * True if the message is for the user on the channel; false if it's for the current user.
     * Used to send "{other user} missed the call" system message.
     */
    forOtherUser?: boolean;
}

export interface ChannelPage {
    message?: string;
}

export interface PageResponse {
    id?: string;
    answered?: boolean;
    declined?: boolean;
    missed?: boolean;
}

export interface ChannelMessageQuery {
    toId?: string;
    toDate?: string;
    limit?: number;
    contentType?: MessageAttachmentType[];
}

export interface ApiChannelMessageRequest {
    message: string;
    senderName?: string;
    readAloud?: boolean;
}

export interface ApiAdminMessageRequest {
    userId: string;
    message: string;
    channelName: string;
    senderName: string;
    messageLanguage?: string;
    readAloud?: boolean;
    mediaUrl?: string;
    channelImageUrl?: string;
    responseUrl?: string;
}

/**
 * A PTT used during Two Walt Demo mode to seed an account with pre-existing conversations.
 */
export interface CannedPttRequest {
    destinationAccountId: string;
    destinationChannelId: string
    message: string;
    messageLanguage: 'en' | 'es';
    senderUserId: string;
}

export interface PreRecordedPttRequest extends CannedPttRequest {
    recordingId: string;
}
