/*
    mobx keystone slow
    everything is not deep
    typescript infer rocks
*/

import { action, computed as mobxComputed, makeObservable, observable, ObservableMap, override as mobxOverride } from 'mobx';
import { isEqual } from 'lodash';

export const prop = function<T>(def?: T | (() => T)): (val?: T) => T {
    return function (val?: T) {
        const init = val ?? (typeof def === 'function' ? (def as () => T)() : def);
        return init;
    };
};

export const dateProp = function () {
    return function (val: string | Date) {
        return !val ? null : val instanceof Date ? val : new Date(val);
    };
};

export const mapProp = function<K, V>() {
    return function (val: { [key: string]: V } | Map<K, V> = {}) {
        return observable.map<K, V>(val, { deep: false });
    };
};

export const arrayProp = function<T>() {
    return function (val: T[] = []) {
        return observable.array<T>(val, { deep: false });
    };
};

export const idProp = prop<string>();
type PropType<T> = { [key in keyof T]: T[key] extends (arg: infer _) => infer S ? S : never };
type NewType<T> = { [key in keyof T]: T[key] extends (arg: infer S) => infer _ ? S : never };

interface BaseModel<T> {
    onInit(): any;
    get snapshot(): T;
    update(values: Partial<T>);
}

// unobservable returns a model that is not mobx
type ModelReturn<T> = (new (values?: Partial<NewType<T>>, unobservable?: boolean) => PropType<T> & BaseModel<PropType<T>>);
type ExtendedModelReturn<T, S, R> = (new (values?: Partial<NewType<T> & R>, unobservable?: boolean) => PropType<T> & BaseModel<PropType<T> & S> & S);

const observableSymbol = Symbol('observable');
const nameSymbol = Symbol('name');

export const Model = function<T>(types: T): ModelReturn<T> {
    const fn: any = class {
        constructor(values?: Partial<NewType<T>>, unobservable?: boolean) {
            const name = fn.prototype[nameSymbol] ?? this.constructor.name;
            Object.keys(types).forEach(type => this[type] = types[type](values?.[type]));
            if (!unobservable) makeObservable(this, fn.prototype[observableSymbol], { name, deep: false });
            if ((this as any).onInit) setTimeout(() => (this as any).onInit(), 0);
        }

        get snapshot() {
            const base = {};
            Object.keys(types).forEach(x => {
                base[x] = this[x] instanceof ObservableMap ? this[x].toJSON() : this[x];
            });
            return base;
        }

        update(values) {
            Object.keys(values).forEach(x => {
                if (types[x]) {
                    const val = types[x](values[x]);
                    if (!isEqual(this[x], val)) {
                        this[x] = types[x](values[x]);
                    }
                }
            });
        }
    };
    fn.prototype[observableSymbol] = { update: action };
    fn.prototype[nameSymbol] = null;
    Object.keys(types).forEach(type => {
        fn.prototype[observableSymbol][type] = observable;
        fn.prototype[type] = undefined;
    });
    return fn;
};

export const ExtendedModel = function<T, S, R>(model: (new (args: R) => S), types: T): ExtendedModelReturn<T, S, R> {
    const fn: any = class extends (model as any) {
        constructor(values?: Partial<NewType<T> & R>, unobservable?: boolean) {
            super(values);
            Object.keys(types).forEach(type => this[type] = types[type](values?.[type]));
            if (!unobservable) makeObservable(this, fn.prototype[observableSymbol]);
        }

        get snapshot() {
            const base = {
                ...super.snapshot,
            };
            Object.keys(types).forEach(x => base[x] = this[x]);
            return base;
        }

        update(values) {
            super.update(values);
            Object.keys(values).forEach(x => {
                if (types[x]) {
                    this[x] = types[x](values[x]);
                }
            });
        }
    };
    fn.prototype[observableSymbol] = { update: mobxOverride };
    Object.keys(types).forEach(type => {
        fn.prototype[observableSymbol][type] = observable;
        fn.prototype[type] = undefined;
    });
    return fn;
};

export function model(name: string) {
    return function (target: any) {
        target.prototype[nameSymbol] = name;
    };
}

export function modelAction(target: any, key: string, prop: PropertyDescriptor) {
    if (!target[observableSymbol]) throw new Error(`${target} is not a model for ${key}`);
    if (prop.enumerable) throw new Error(`${target} ${key} cannot model action an enumerable property`);
    target[observableSymbol][key] = action;
}

export function computed(target: any, key: string, prop: PropertyDescriptor) {
    if (!target[observableSymbol]) throw new Error(`${target} is not a model for ${key}`);
    if (prop.enumerable) throw new Error(`${target} ${key} cannot model action an enumerable property`);
    target[observableSymbol][key] = mobxComputed;
}

export function override(target: any, key: string, prop: PropertyDescriptor) {
    if (!target[observableSymbol]) throw new Error(`${target} is not a model for ${key}`);
    if (prop.enumerable) throw new Error(`${target} ${key} cannot model action an enumerable property`);
    target[observableSymbol][key] = mobxOverride;
}

export class Context<T> {
    value: T;

    get(_?: any) { return this.value; }
    setDefault(value: T) { this.value = value; }
    getDefault() { return this.value; }
}

export function createContext<T>() {
    return new Context<T>();
}

export type RefResolver<T> = (ref: string) => T;
export class Ref<T> {
    constructor(public id: string, private resolver: RefResolver<T>) {
    }

    get current() {
        return this.resolver(this.id);
    }

    get maybeCurrent() {
        return this.resolver(this.id);
    }
}
export function customRef<T>(fn: RefResolver<T>) {
    return (value: string) => {
        return new Ref<T>(value, fn);
    };
}
